import React from 'react';
import Banner from '../components/Portfolio/Banner';
import OurPort from '../components/Portfolio/OurPort';

function Portfolio() {
  return (
    <>
        <Banner />
        <OurPort />
    </>
  )
}

export default Portfolio