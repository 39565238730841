import React from "react";
import Every from "../components/OurBrand/Every";
import Great from "../components/OurBrand/Great";
import View from "../components/OurBrand/View";
import Who from "../components/OurBrand/Who";

function OurBrand() {
  return (
    <>
      <Every />
      <Great />
      <View />
      <Who />
    </>
  );
}

export default OurBrand;
