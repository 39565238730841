import React from 'react'
import Banner from '../components/Services/Banner'
import Full from '../components/Services/Full'
import Deliverables from '../components/Services/Deliverables'
import Testimonials from '../components/Services/Testimonials'
import How from '../components/Services/How'

function Services() {
  return (
    <>
        <Banner />
        <Full />
        <Deliverables  />
        <Testimonials />
        <How />
        
    </>
  )
}

export default Services