import React from 'react';
import Banner from '../components/GetAQuote/Banner';
import Time from '../components/GetAQuote/Time';
import Expect from '../components/GetAQuote/Expect';


function GetAQuote() {
  return (
   <>
    <Banner />
    <Time />
    <Expect />
   </>
  )
}

export default GetAQuote